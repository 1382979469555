
import axios from 'axios'

export default {
  name: 'FieldView',
  data: () => {
    return {
      uploadState: {
        disabled: false,
        animate: ''
      },
      fetchState: {
        disabled: false,
        animate: ''
      },
      locationState: {
        disabled: false,
        animate: ''
      },
      text: '',
      lat: '',
      lon: '',
      location: '---',
      images: [],
      locations: ['---'],
      allowState: {
        loc: true
      },
      gmap: null
    }
  },
  created: async function () {
    this.getPosition()
    await this.fetch()
  },
  methods: {
    onFileChange(e) {
      this.images = []
      let idx = 0
      for (const file of e.target.files) {
        this.images.push({idx: idx, blob: file, url: URL.createObjectURL(file)})
        idx++
      }

      this.$refs.fileInput.reset()
    },
    getPosition() {
      this.locationState.animate = 'fade'
      navigator.geolocation.getCurrentPosition((pos) => {
        this.lat = pos.coords.latitude
        this.lon = pos.coords.longitude
        this.locationState.animate = ''
        this.getLocation()
      }, function(err) {
        this.locationState.disabled = true
        console.log(err)
      }, {
        enableHighAccuracy: true
      });
    },
    async getLocation() {
      if (this.lat == '' && this.lon == '') return;

      const result = await axios.get(`${process.env.VUE_APP_ARTICLE_APP}/nearby/${this.lat}/${this.lon}`)
      this.locations = result.data.locations
      this.locations.unshift('---')
    },
    async upload() {
      this.uploadState.animate = 'fade'
      this.uploadState.disabled = true

      let image_urls = []
      for (const image of this.images) {
        const formdata = new FormData();
        formdata.append('image', image.blob)

        let image_result = await axios.post(process.env.VUE_APP_UPLOAD_APP + '/upload', formdata, {headers: {'content-type': 'multipart/form-data'}});

        image_urls.push(process.env.VUE_APP_UPLOAD_APP + '/' + image_result.data.id);
      }

      const postData = {
        text: this.text,
        lat: this.lat,
        lon: this.lon,
        location: this.location,
        images: image_urls
      }

      const result = await axios.post(process.env.VUE_APP_ARTICLE_APP + '/post', postData)

      this.$parent.posts.unshift(result.data.id)
      
      this.uploadState.animate = ''
      this.uploadState.disabled = false

      this.text = ''
      this.location = '---'
      this.locations = ['---']
      this.images = []

      this.getPosition();
    },
    async fetch() {
      this.fetchState.animate = 'spin';
      this.fetchState.disabled = true;

      const result = await axios.get(process.env.VUE_APP_ARTICLE_APP + '/');

      this.$parent.posts = result.data.data.map((val) => val.name).reverse();
      
      this.fetchState.animate = '';
      this.fetchState.disabled = false;
    },
    removeImage(idx) {
      this.images = this.images.filter(image => image.idx != idx)
    },
    changeLocState() {
      this.allowState.loc = !this.allowState.loc

      if (this.allowState.loc) {
        this.getPosition();
      } else {
        this.lat = ''
        this.lon = ''
      }
    }
  }
}
