
import axios from 'axios'
import {DateTime} from 'luxon'
import {Post as PostType} from '../types/Post'

export default {
  name: 'PostView',
  props: {
    id: String
  },
  data: () => {
    return {
      post: {} as PostType,
      maps_key: process.env.VUE_APP_MAPS_API_KEY
    }
  },
  mounted: async function () {
    const response = await axios.get(process.env.VUE_APP_ARTICLE_APP + '/post/' + this.id);

    this.post = response.data.data as PostType;
  },
  filters: {
      unixtodate: function (date: number): string {
        if (typeof date == 'undefined') return ''

        return DateTime.fromSeconds(date).toFormat('yyyy/LL/dd HH:mm:ss')
      }
  }
}
